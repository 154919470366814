// src/templates/text-template.js

import React from "react"
import { graphql } from "gatsby"
import HeadMetadata from "../components/headMetadata"
import HeaderBar from "../components/headerBar"
import Footer from "../components/footer"
import { BrightSection } from "../components/sections"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <div>
      <HeadMetadata {...frontmatter} />
      <HeaderBar />
      <BrightSection>
        <div
          className="text-markdown"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </BrightSection>
      <Footer />
    </div>
  )
}
export const pageQuery = graphql`

  fragment frontmatterInfo on MarkdownRemark {
    frontmatter{
    date(formatString: "MMMM DD, YYYY")
    path
    title
    }

  }

  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      ...frontmatterInfo
      
    }
  }
`
